import React, { Fragment, PureComponent, Suspense } from 'react'
import isEqual from 'react-fast-compare'

// REDUX STUFF
import { connect } from 'react-redux'
import * as actions from '../redux/actions'

import loadable, { lazy } from '@loadable/component'
import debounce from 'lodash.debounce'

// Router, Mobile, & SEO
import { MediaMatcher } from 'react-media-match';
import { isMobile } from 'react-device-detect';
import SEO from '../components/seo/'

// Layouts
const Header = loadable(() => import('components/elements/header'))
const Logo = loadable(() => import('components/elements/logo'))

const Navigation = loadable(() => import('components/events/navigation'))
const TwoColumnLayout = loadable(() => import('components/layouts/TwoColumnLayout'))
const ItemDetails = loadable(() => import('components/layouts/ItemDetails'))
const ListSearch = loadable(() => import('components/layouts/ListSearch'))
const MobileMain = loadable(() => import('./MobileMain'))

// Lazy Load
const Location = lazy(() => import('components/shared/location'))
const SignUp = lazy(() => import('components/elements/SignUp'))
const Toast = lazy(() => import('components/elements/Message'))
const EventsMap = lazy(() => import('components/events/events_map.js'))

import './Main.scss';

// Page-specific styles
import 'vibemap-constants/design-system/semantic/dist/components/checkbox.min.css'
import 'vibemap-constants/design-system/semantic/dist/components/container.min.css'
import 'vibemap-constants/design-system/semantic/dist/components/search.min.css'

let timer = null
class Main extends PureComponent {

    // Get initial props from server-side
    static async getInitialProps({ req, res, match, store, location }) {
        // TODO: Get details?

        try {
            this.setStateFromParams(req.query)
        } catch (error) { console.log('Problem parsing history.')}


        if (match.params.city) {
            console.log('Handle route for city: ', match.params.city)
        }
    }

    constructor(props) {
        super(props)

        // State includes some globals only for the main page;
        // Most other UI state is managed by Redux
        this.state = {
            // TODO: set state form YAML
            event_categories: [/.*.*/, 'art', 'arts', 'comedy', 'community', 'food', 'food & drink', 'festive', 'free', 'local', 'other', 'recurs', 'music', 'urban'],
            place_categories: ['Arts & Entertainment', 'Food'],
            intervalIsSet: false,
            loading: true,
            doSearch: false,
            isReady: false,
            timedOut: false,
            mergeTopPicks: false,
            time_of_day: 'morning'
        }

        // Load right away on mobile
        if (isMobile === true) this.getPlacesOrEvents(true)
    }

    componentDidMount() {
        // TODO: Pattern for if data is loaded or errored out
        const {
            currentLocation,
            fetchCities,
            fetchVibes,
            fetchCategories,
            history,
            i18n,
            language,
            setIsBrowser,
            setZoom,
            tips,
            viewport,
            zoom } = this.props

        // Set current language from backend store
        //i18n.changeLanguage(language);

        // Always do a fresh load of UI
        /* TODO: This is a performance bottle neck */
        //fetchCities()
        fetchVibes()
        fetchCategories()
            .then(() => this.setState({ loading: false }))

        // And data
        //this.fetchPlaces(true)

        // Store Browser or Server this in Redux
        const isBrowser = !!((typeof window !== 'undefined' && window.document && window.document.createElement))
        setIsBrowser(isBrowser)

        const randomNumber = Math.floor(Math.random() * tips.length)
        const randomTip = tips[randomNumber]
        this.setState({ randomTip : randomTip })
    }

    // Should update and Debounce API Requests
    componentDidUpdate(prevProps, prevState) {

        const {
            currentLocation,
            detailsShown,
            detailsId,
            hasLocation,
            updatePlacesFromNav } = this.props

        const { doSearch } = this.state

            // TODO: should be a switch statement?
        let updateData = false
        let refreshResults = false
        //let shouldSearch = false

        const map_is_ready = this.props.mapReady === true && !isEqual(prevProps.mapReady, this.props.mapReady)
        const bounds_ready = !isEqual(prevProps.boundsReady, this.props.boundsReady)

        // TODO: Move this to actions or service layer as shouldFetchData
        // TODO: measure distance between current and previous event
        // TODO: Only refesh if the map moved by a significant jump...
        // If they close together, merge the data in fetchPlaces.
        const location_changed = !isEqual(prevProps.currentLocation.latitude, currentLocation.latitude)
        const vibe_changed = !isEqual(prevProps.vibes, this.props.vibes)
        const search_changed = !isEqual(prevProps.searchTerm, this.props.searchTerm) && (this.props.searchTerm.length === 0 || this.props.searchTerm.length > 2)
        const ordering_changed = !isEqual(prevProps.ordering, this.props.ordering)
        const activity_changed = !isEqual(prevProps.activity, this.props.activity)
        const place_type_changed = !isEqual(prevProps.placeType, this.props.placeType)
        const page_changed = !isEqual(prevProps.currentPage, this.props.currentPage)
        const pixels_changed = !isEqual(prevProps.pixelDistance, this.props.pixelDistance)
        const zoom_changed = !isEqual(prevProps.zoom, this.props.zoom)
        const details_changed = !isEqual(prevProps.detailsShown, this.props.detailsShown)

        // Conditions for fetch
        if (vibe_changed || ordering_changed || search_changed || activity_changed || place_type_changed || location_changed || zoom_changed) {
            updateData = true
            refreshResults = true
        }

        const shouldSearch = doSearch || search_changed || place_type_changed || vibe_changed || (location_changed && isMobile)

        const hasPlaces = this.props.placesData.length > 0

        // After some time, check if there are no results
        if (!hasPlaces) {
            if (timer == null) { // Just once
                timer = setTimeout(() => {
                    if (!hasPlaces) {
                        this.setState({ doSearch: true })
                    } else {
                        this.setState({ doSearch: false })
                    }
                    window.clearTimeout(timer)
                }, 10000)
            }
        } else {
            window.clearTimeout(timer)
            this.setState({
                doSearch: false
            })
        }

        // See shared/location: if (location_changed) this.getBounds()
        if (page_changed) {
            refreshResults = true
            this.pageTopPicks(refreshResults)
        }

        // TODO: establish reproducable relationship between custer distance and icon size
        if (pixels_changed) this.setState({ clusterSize: this.props.pixelDistance * 60 })

        /* Handle UI State and Data Loading */
        if (this.props.detailsShown === true) updateData = false

        /* TODO: Not sure of the best react pattern for handling refresh state, but this works. */
        /* TODO: this logic can move to either actions or the VibeMap service */
        if (this.props.currentLocation.latitude === 0 && this.props.boundsReady === false) updateData = false

        //if (hasLocation === false) updateData = false

        // Only update data if the map and searh radius area ready.
        if (this.props.mapReady && this.props.boundsReady && bounds_ready) updateData = true

        // Reset mergeTopPicks; if the results should change
        if (refreshResults) this.setState({ mergeTopPicks: false })

        /* Once map and radius are ready, fetch data */
        //console.log('Should search: ', shouldSearch, refreshResults)
        if (shouldSearch === true) this.getPlacesOrEvents(refreshResults)

    }

    setStateFromParams (params) {
        let { place_type, activity, longitude, latitude, message, vibes, viewport, zoom } = params

        const {
            setLocation,
            lookUpActivity,
            setMessage,
            setShowMessage,
            setPlaceType,
            setHasLocation,
            setActivity,
            setVibes,
            setZoom } = this.props

        // Set Redux Store from URL on server so it can be used for SEO
        if (place_type) setPlaceType(place_type)

        if (latitude && longitude) setLocation({ latitude: latitude, longitude: longitude })

        if (message) {
            if (message === 'not_found') {
                setMessage({
                    id: 'not_found',
                    title: 'Place not found',
                    type: 'warning',
                    body: 'The place you were looking for is missing or removed. Please search again on this page.'
                })
                setShowMessage(true)
            }
        }

        if (zoom) setZoom(zoom)

        if (activity) setActivity(activity)

        if (params.mainVibe) {
            if (params.mainVibe === 'wild') params.mainVibe = 'chill'
            this.props.setMainVibe(params.mainVibe)
        }

        if (typeof (vibes) === 'string') vibes = vibes.split(',')

        if (vibes) setVibes(vibes)
    }

    getPlacesOrEvents = debounce((refreshResults, num_results) => {
        // Read from URL params or app state
        switch (this.props.placeType) {
            case 'events':
                this.setState({ mergeTopPicks: false })
                this.fetchEvents(refreshResults)
                break
            case 'places':
                this.setState({ mergeTopPicks: false })
                this.fetchPlaces(refreshResults)
                break
            case 'guides':
                this.setState({ mergeTopPicks: false })
                this.fetchGuides()
                //this.fetchPlaces(refreshResults)
                break
            // i.e. both
            default:
                //console.log('refreshResults: ', refreshResults)
                this.setState({ mergeTopPicks: true })
                this.fetchEvents(refreshResults)
                this.fetchPlaces(refreshResults)
                break
        }
    }, 500, { leading: false, trailing: true})

    // Wrapping a function handled by Thunk calls to the Vibemap service
    fetchPlaces(refreshResults) {
        const today = new Date()
        const currentTime = today.toISOString()

        const { distance, bounds, currentLocation, activity, days, ordering, vibes, mainVibe, searchTerm, zoom } = this.props
        const point = `${currentLocation.longitude},${currentLocation.latitude}`

        // TODO: Make options an object that matches the API
        // Inlcude a sorting option
        let options = {
            activity: activity,
            bounds: bounds,
            days: days,
            distance: distance,
            zoom: zoom,
            ordering: ordering,
            point: point,
            search: searchTerm,
            time: currentTime,
            vibes: vibes,
            mainVibe: mainVibe
        }

        // State for timeout in HOC
        if (this.state.timedOut === true) this.setState({ timedOut: false, searching: false })
        // Important: This fetches the data and update the state in Redux
        const places = this.props.fetchPlaces(options, refreshResults)
    }

    fetchEvents(refreshResults) {
        // TODO: Enable event search once events are available
        const today = new Date()
        const currentTime = today.toISOString()

        const { distance, bounds, currentLocation, activity, days, ordering, vibes, mainVibe, searchTerm, zoom } = this.props
        const { fetchEvents } = this.props
        const point = `${currentLocation.longitude},${currentLocation.latitude}`

        // TODO: Make options an object that matches the API
        // Inlcude a sorting option
        let options = {
            activity: activity,
            bounds: bounds,
            days: days,
            distance: distance,
            ordering: ordering,
            point: point,
            search: searchTerm,
            time: currentTime,
            vibes: vibes,
            mainVibe: mainVibe,
            zoom: zoom,
        }

        // State for timeout in HOC
        if (this.state.timedOut === true) this.setState({ timedOut: false, searching: false })
        // Important: This fetches the data and update the state in Redux
        const places = fetchEvents(options, refreshResults)

    }

    // Function is called upon when page is switched (arrows/numbers to paginate)
    // Also referenced in Main.js pageTopPicks (when you change pages)
    pageTopPicks(refreshResults) {
        const { currentPage, placesData, numTopPicks, setTopPicks} = this.props
        const num_results = placesData.length

        // If there are less results than results_per_page (numTopPicks), set default page number to 1
        const num_pages = num_results <= numTopPicks ? 1 : Math.ceil(num_results / numTopPicks)
        const first = (currentPage -1) * numTopPicks

        // If not on last page, add numTopPicks to last (20).
        // Otherwise, if multiple of numTopPicks, add numTopPicks. If not, add remainder
        const last = (
            (currentPage !== num_pages) ? first + numTopPicks :
            (currentPage == num_pages && (num_results%numTopPicks) !== 0) ? first + (num_results%numTopPicks)  :
            (currentPage == num_pages && (num_results%numTopPicks) == 0) ? first + numTopPicks  : 0
          )
        console.log("pageTopPicks called", last)
        let top_picks = placesData.slice(first, last)
        setTopPicks(top_picks, refreshResults, this.state.mergeTopPicks)
    }

    handleWindowSizeChange = () => {
        this.props.setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth
        })
    }

    // Aever let a process live forever
    // always kill a process everytime we are done using it
    componentWillUnmount() {
        if (this.state.intervalIsSet) {
            clearInterval(this.state.intervalIsSet)
            this.setState({ intervalIsSet: null })
        }
    }

    render() {

        let {
            cities,
            currentLocation,
            history,
            detailsShown,
            placeType,
            mainVibe,
            message,
            showMessage,
            showSignUp,
            placesLoading,
            handleClick,
            topPicks,
            tips } = this.props

        const { loading, randomTip, width } = this.state

        const size = (isMobile) ? 100 : 140

        // TODO: Handle error(s)
        if (loading) return (<div className="full-page-loader" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
            <Logo size={size} />
            <h3 className='randomTip'>{randomTip}</h3>
        </div>)

        let navigation = <Navigation
            activities={this.state.event_categories}
            activity={this.state.activity}
            isMobile={isMobile} />

        // Pick the list that should be display
        // TODO: Create a cleary way to manage the state between events, places, adn guides.
        let list_data = null
        if (placeType === 'places' || placeType === 'events' || placeType === 'both') list_data = topPicks
        if (placeType === 'guides') list_data = guidesData

        let title = 'Vibemap'
        let vibe = mainVibe
        let description = `Explore ${vibe} things to do near you`

        if (mainVibe) {
            title += ' | ' + mainVibe + ' vibes'
        }

        if (currentLocation.name) {
            title += ' | ' + currentLocation.name
            description += ' in ' + currentLocation.name
        }

        // TODO: Also handle guide here.
        const LeftPanel = detailsShown ?
            <ItemDetails id={this.props.detailsId} clearDetails={this.clearDetails} /> :
            <ListSearch data={list_data} loading={placesLoading} type='places' />

        const Map = <Fragment>
            {placesLoading && <Logo size={100} /> }
            <Suspense fallback={<div className='loading'><Logo size={100} /></div>}>
                <Location />
                <EventsMap
                    setLocationParams={this.setLocationParams}
                    isMobile={isMobile} />
            </Suspense>

        </Fragment>

        let mobile =
            <Fragment>
                <Header isMobile={isMobile} />
                <MobileMain />
            </Fragment>

        let web = <Fragment>
            <Header isMobile={isMobile} />
            {navigation}
            <TwoColumnLayout
                leftPanel={LeftPanel}
                rightPanel={Map}
                showLeft={this.props.showList} />

            <Suspense fallback={<div>Loading...</div>}>
                <SignUp open={showSignUp}/>
                <Toast
                    message={message}
                    showMessage={showMessage} />
            </Suspense>
        </Fragment>

        return (
            <div className={'Main ' + (isMobile ? 'mobile' : 'web')}>
                <SEO
                    title={title}
                    description={description} />

                <MediaMatcher
                    desktop={web}
                    mobile={mobile} />

            </div>
        );
    }
}

const mapDispatchToProps = {
    ...actions
}

// Map is a container component that manages the following state with Redux Thunks
const mapStateToProps = state => ({
    isBrowser: state.isBrowser,
    language: state.language,
    mapboxToken: state.mapboxToken,
    showMessage: state.showMessage,
    showSignUp: state.showSignUp,
    message: state.message,

    // Map
    bounds: state.map.bounds,
    boundsReady: state.map.boundsReady,
    distance: state.map.distance,
    layersChanged: state.map.layersChanged,
    mapSize: state.map.mapSize,
    mapReady: state.map.mapReady,
    pixelDistance: state.map.pixelDistance,
    viewport: state.map.viewport,
    zoom: state.map.zoom,

    // Navigation
    activity: state.nav.activity,
    cities: state.nav.allCities,
    currentLocation: state.nav.currentLocation,
    hasLocation: state.nav.hasLocation,
    currentPage: state.nav.currentPage,
    days: state.nav.days,
    mainVibe: state.nav.mainVibe,
    numTopPicks: state.nav.numTopPicks,
    ordering: state.nav.ordering,
    placeType: state.nav.placeType,
    searchTerm: state.nav.searchTerm,
    tips: state.nav.tips,
    vibes: state.nav.vibes,

    // Place
    detailsId: state.places.detailsId,
    placesData: state.placesData,
    placesLoading: state.places.placesLoading,

    // List
    detailsShown: state.detailsShown,
    showList: state.showList,
    topPicks: state.topPicks,
    windowSize: state.windowSize
})

// TODO: I think the withTranslation wrappter causes issue with After.js getInitialProps.
export default connect(mapStateToProps, mapDispatchToProps)(Main);