import React, { Component } from "react";

import { Helmet } from 'react-helmet'

// Add JSON-lD Schema.org attributes
import { helmetJsonLdProp  } from "react-schemaorg";
import { Place, Event } from "schema-dts";


const SEO = props => {
    const {
        title,
        description,
        canonical,
        categories,
        subcategories,
        image,
        aggregate_rating,
        address,
        isProduct,
        latitude,
        longitude,
        price,
        telephone,
        url,
        locale,
        website,
        schema,
        reviews,
        startDate,
        endDate,
        tips,
        type,
        vibes } = props

    const all_keywords = categories.concat(subcategories, vibes)

    const tip_reviews = tips.map(tip => {
        return {
            author: "Vibemap User",
            //datePublished: "",
            reviewRating: {
                "ratingValue": aggregate_rating
            },
            description: tip,
        }
    })

    const all_reviews = reviews.concat(tip_reviews)
    const reviewCount = all_reviews.length > 0 ? all_reviews.length : 0

    // Automatically set Schema type
    // Specification for events: https://schema.org/Event
    const schemaType = type == 'event'
        ? 'Event'
        : isProduct
            ? 'Product'
            : 'LocalBusiness'

    const schemaData = {
        "@context": "http://schema.org/",
        "@type": schemaType,
        name: title,
        description: description,
        // TODO: add the geo field
        image: image,
        ...(type == 'event'
            ? { location: { address: address } }
            : { address: address }
        ),
        latitude: latitude,
        longitude: longitude,
        ...(startDate && { startDate: startDate }),
        ...(endDate && { endDate: endDate }),
        ...(type !== 'event' && {
            priceRange: '$$',
            aggregateRating: {
                "@type": "AggregateRating",
                ratingValue: aggregate_rating,
                reviewCount: reviewCount
            }
        }),
        ...(isProduct && {
            brand: {
                "@type": "Organization",
                name: title
            }
        }),
        ...(isProduct && {
            offers: {
                "@type": "Offer",
                availability: "http:schema.org\InStock",
                price: price,
                priceCurrency: 'USD',
                url: url
            }
        }),
        telephone: telephone,
        url: url,
        servesCuisine: categories.join(', '),
        review: all_reviews
    }

    const json = [
        helmetJsonLdProp(schemaData)
    ]

    return <Helmet
        script={schema ? json : [] }>
        <title>{title}</title>
        <meta property="og:title" content={title} />

        <meta name="description" content={description} />
        <meta property="og:description" content={description} />

        <meta property="og:image" content={image} />
        <meta property="og:site_name" content={website}/>
        <meta property="og:locale" content={locale} />

        <meta name="keywords" content={all_keywords.join(', ')} />

        <meta property="twitter:card" content="summary_large_image"></meta>
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description}/>
        <meta property="twitter:image" content={image}/>
        <meta property="twitter:domain" content="vibemap.com"/>
        <meta property="twitter:site" content="@vibemap"/>
        {canonical
            ? <link rel="canonical" href={canonical} />
            : null
        }

    </Helmet>;
}

SEO.defaultProps = {
    title:'Vibemap',
    company: 'Vibemap',
    image: 'https://pbs.twimg.com/profile_images/1270800120452222977/GFhjmGCz_400x400.jpg',
    website: 'Vibemap.com',
    locale: 'Oakland',
    description: 'Find your vibe | Explore things to do',
    canonical: null,
    categories: [],
    subcategories: [],
    type: 'places',
    reviews: [],
    tips: [],
    vibes: [],
    schema: false
}

export default SEO